import React from "react"
import { Link as ScrollLink } from "react-scroll"

class TableOfContents extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isTableOfContentsOpen: false,
    }
  }

  closeTableOfContents = () => {
    this.setState({ isTableOfContentsOpen: false })
  }

  openTableOfContents = () => {
    this.setState({ isTableOfContentsOpen: true })
  }

  render() {
    const { items, isMobile } = this.props
    const { isTableOfContentsOpen } = this.state

    return (
      <>
        {isMobile ? (
          <div
            className={`d-block d-lg-none position-fixed bottom-0 w-100 bg-white z-index-3000 shadow-lg  ${
              isTableOfContentsOpen
                ? "top-corner-border-radius blog-table-of-contents-mobile-container"
                : ""
            }`}
          >
            <div>
              {isTableOfContentsOpen && (
                <div className="p-3 py-4">
                  <h2 className="text-big fw-bolder blog-text-dark mb-4">
                    Table Of Contents
                  </h2>
                  <ul className="d-flex flex-column gap-2 blog-table-of-contents p-0 m-0">
                    {items.map((item, i) => (
                      <li key={i}>
                        <ScrollLink
                          className="text-regular blog-table-of-contents-list-item"
                          onClick={() => this.closeTableOfContents()}
                          to={item.href.slice(1)}
                          smooth={true}
                          offset={70}
                          duration={500}
                        >
                          {item.text}
                        </ScrollLink>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <div className="p-3 blog-table-of-contents-button-box-shadow">
                {this.state.isTableOfContentsOpen ? (
                  <button
                    onClick={() => this.closeTableOfContents()}
                    style={{
                      boxShadow:
                        "0px 0px 10px 10px rgba(27, 39, 100, 0.24) !important",
                    }}
                    className="cta cta-button btn btn-primary button-tall text-medium bg-dark-blue d-block"
                  >
                    Close table of contents
                  </button>
                ) : (
                  <button
                    onClick={() => this.openTableOfContents()}
                    style={{
                      boxShadow:
                        "0px 0px 10px 10px rgba(27, 39, 100, 0.24) !important",
                    }}
                    className="cta cta-button btn btn-primary button-tall text-medium bg-dark-blue d-block"
                  >
                    Open Table of contents
                  </button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="stick-element-to-top d-none d-lg-block">
            <h2 className="text-big fw-bolder blog-text-dark mb-4">
              Table of Contents
            </h2>
            <ul className="d-flex flex-column gap-2 blog-table-of-contents p-0 m-0">
              {items.map((item, i) => (
                <li key={i}>
                  <ScrollLink
                    className="text-regular blog-table-of-contents-list-item"
                    to={item.href.slice(1)}
                    smooth={true}
                    offset={70}
                    duration={500}
                  >
                    {item.text}
                  </ScrollLink>
                </li>
              ))}
            </ul>
          </div>
        )}
      </>
    )
  }
}

export default TableOfContents
