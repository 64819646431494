import React from "react"
import { Link } from "gatsby"

class BlogPostMobileCTA extends React.Component {
  render() {
    return (
      <div className="d-block d-lg-none position-fixed bottom-0 w-100 bg-white z-index-3000 shadow-lg">
        <div className="p-3 blog-table-of-contents-button-box-shadow">
          <a
            style={{
              boxShadow: "0px 0px 10px 10px rgba(27, 39, 100, 0.24) !important",
            }}
            className="cta cta-button btn btn-primary button-tall text-medium bg-dark-blue d-block"
            href="https://wealthyhood.onelink.me/TwZO/tj40hooh"
            target="_blank"
          >
            Start Investing
          </a>
        </div>
      </div>
    )
  }
}

export default BlogPostMobileCTA
