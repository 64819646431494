import React from "react"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import slugify from "../utils/slugify"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogAuthorPreview from "../components/blog/blog-author-preview"
import TableOfContents from "../components/blog/blog-post-table-of-contents"
import BlogAuthor from "../components/blog/blog-author"
import BlogPostMobileCTA from "../components/blog/blog-post-mobile-cta"
import DownloadAppBtn from "../components/download-app-btn"
import EmailCta from "../components/blog/email-cta"
import BlogMultiplePostPreview from "../components/blog/blog-multiple-post-preview"
import BlogFooterCta from "../components/blog/blog-footer-cta"
import { formatDateToLongFormWithoutComma } from "../utils/date"
import { Link } from "gatsby"

const tocTagRegex = /<toc>(.*?)<\/toc>/

class BlogPostTemplate extends React.Component {
  // This is for cases where the heading might be partially underlined and have two child nodes
  // so you can't just get the text (.value) from the first one in the array
  getPlainTextFromHeader(contentNode) {
    return contentNode.reduce((acc, current) => {
      return acc + current.value
    }, "")
  }

  getHeadersFromNodes(nodesArray) {
    return nodesArray
      .filter(node => {
        const isHeading =
          node.nodeType === BLOCKS.HEADING_2 ||
          node.nodeType === BLOCKS.HEADING_3
        const fullText = this.getPlainTextFromHeader(node.content)
        const containsTocTag = tocTagRegex.test(fullText)

        return isHeading && containsTocTag
      })
      .map(heading => {
        const fullText = this.getPlainTextFromHeader(heading.content)
        const plainText = fullText.match(tocTagRegex)[1].trim() // Extract text within <toc> tags

        return {
          text: plainText,
          href: `#${slugify(plainText)}`,
        }
      })
  }

  // Function to render heading nodes (H2 and H3)
  renderHeadingNode = (node, headingType) => {
    const initialPlainText = this.getPlainTextFromHeader(node.content)
    const tocMatch = initialPlainText.match(tocTagRegex)

    let idAttribute = ""
    if (tocMatch) {
      const textInsideTocTags = tocMatch[1].trim()
      idAttribute = ` id=${slugify(textInsideTocTags)}`
    }

    return `<${headingType} ${idAttribute}>${initialPlainText}</${headingType}>`
  }

  renderUnorderedList = node => {
    // if the unordered list includes a <summary> tag it means it's a summary for the analyst insights. So  give it a background color and some padding.
    let listItemsHTML = ""
    let isSummary = false

    node.content.forEach(listItemNode => {
      if (listItemNode.content[0].content[0].value.includes("<summary>")) {
        isSummary = true
        //remove any <summary> inside the string. Also remove any white space at the start and the end
        // This line is a bit hacky
        listItemNode.content[0].content[0].value = listItemNode.content[0].content[0].value
          .replace(/<summary>/g, "")
          .trim()
      }
      const listItemContent = documentToHtmlString(listItemNode)
      listItemsHTML += `<li>${listItemContent}</li>`
    })

    if (isSummary) {
      // add the styles
      return `<ul style="background-color: #f1f3fd; padding: 24px 24px 24px 48px; margin-bottom: 48px; border-radius: 8px">${listItemsHTML}</ul>`
    }
    return `<ul>${listItemsHTML}</ul>`
  }

  transformRichTextToHTML = (richTextDocument, imageReferences) => {
    const options = {
      renderNode: {
        // We find the relevant image info by associating the node.data.target.sys.id to the relavant image reference to get the url and to include it inside the HTML
        // as <img> tags.
        [BLOCKS.EMBEDDED_ASSET]: node => {
          let image = imageReferences.find(
            image => image.contentful_id === node.data.target.sys.id
          )
          return `<img 
        class="blog-post-content-image-container"
        src=${image.url} alt=${image.title}/>`
        },
        [BLOCKS.HEADING_2]: node => this.renderHeadingNode(node, "h2"),
        [BLOCKS.HEADING_3]: node => this.renderHeadingNode(node, "h3"),
        [BLOCKS.UL_LIST]: node => this.renderUnorderedList(node),
      },
    }

    let htmlString = documentToHtmlString(richTextDocument, options)

    // Removing <toc> and <toc/> tags from the HTML string
    htmlString = htmlString.replace(/<toc>/g, "").replace(/<toc\/>/g, "")

    return htmlString
  }
  render() {
    const postPageData = this.props.pageContext
    const responseDocumentContent = JSON.parse(postPageData.content.raw)
    console.log("responseDocumentContent: ", responseDocumentContent)
    const responseDocumentReferences = postPageData.content.references
    const category = {
      name: postPageData.category.name,
      color: postPageData.category.color,
    }
    const contentHTML = this.transformRichTextToHTML(
      responseDocumentContent,
      responseDocumentReferences
    )
    const tableOfContentItems = this.getHeadersFromNodes(
      responseDocumentContent.content
    )
    const author = {
      name: postPageData.author.name,
      description: postPageData.author.shortDescription,
      image: postPageData.author.image,
      mail: postPageData.author.mail,
      linkedin: postPageData.author.linkedin,
      reddit: postPageData.author.reddit,
      medium: postPageData.author.medium,
      twitter: postPageData.author.twitter,
    }
    const {
      metaTitle,
      metaImage,
      metaDescription,
      title,
      tags,
      date,
      slug,
      relevantPosts,
    } = postPageData
    return (
      <Layout
        activePage={"post"}
        backgroundColor={"#fff"}
        headerBackgroundColor={"#F6F8FD"}
      >
        <SEO
          description={metaDescription}
          title={metaTitle}
          canonicalUrlPath={`/${category.name}/${slug}`.toLowerCase()}
          image={metaImage.file.url}
          isDynamicImage={true}
        />
        <div className="blog-font-family m-0 p-0">
          {/*Blog Post Header*/}
          <div
            style={{
              backgroundColor: "#F6F8FD",
            }}
          >
            <div className="container space-top-2 space-bottom-1 d-flex flex-column gap-4">
              <div className="d-flex gap-4">
                <div>
                  <Link className="blog-breadcrump blog-text-dark" to={`/`}>
                    <span>Wealthybites</span>
                  </Link>{" "}
                  /{" "}
                  <Link
                    className="blog-breadcrump blog-text-dark"
                    to={`/${slugify(category.name)}/`}
                  >
                    <span>{category.name}</span>
                  </Link>
                </div>
                <span className="d-none d-lg-block blog-text-dark fw-bold">
                  {formatDateToLongFormWithoutComma(date)}
                </span>
              </div>
              <h1 className="blog-header-title blog-text-dark m-0">{title}</h1>
              <BlogAuthorPreview
                date={date}
                image={author.image.file.url}
                name={author.name}
                className="d-block d-lg-none blog-text-dark"
              />
            </div>
          </div>

          {/*Blog Post Content*/}
          <div className="container blog-post-content-container space-top-2 space-bottom-2 mb-4 px-lg-0">
            <div className="d-flex">
              <div
                className="d-none d-lg-block blog-post-table-of-contents-container"
                // Make this sticky at top of the screen when user scrolls
              >
                {tableOfContentItems.length ? (
                  <TableOfContents items={tableOfContentItems} />
                ) : (
                  ""
                )}
              </div>
              <div
                className="col-12 px-xl-7 blog-post-html-container"
                dangerouslySetInnerHTML={{
                  __html: contentHTML,
                }}
              ></div>
              <div
                className="d-none d-lg-block blog-post-author-container"
                // Make this sticky at top of the screen when user scrolls
              >
                <div className="stick-element-to-top">
                  <BlogAuthor
                    alignment={"vertical"}
                    image={author.image.file.url}
                    name={author.name}
                    linkedin={author.linkedin}
                    twitter={author.twitter}
                    reddit={author.reddit}
                    medium={author.medium}
                    mail={author.mail}
                    description={author.description}
                  />
                  <div className="d-none d-xl-block">
                    <div className="mt-5 p-4 d-flex flex-column blog-post-form gap-4">
                      <h3 className="text-white fw-bold m-0 blog-post-form-title">
                        Join the Investors' Club
                      </h3>
                      <p className="text-regular m-0 text-white d-xxl-only">
                        Get access to professional investing tools for free and
                        start building your wealth today!
                      </p>
                      <DownloadAppBtn
                        color="light"
                        className="button-short button-width-100"
                      />
                      <p className="text-small text-center m-0 text-white">
                        Capital at risk
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* CTA */}
          <div className="container space-2 my-3">
            <EmailCta />
          </div>

          {/*  Relevant post */}
          {relevantPosts?.length && (
            <div className="container space-bottom-2 mb-4 editors-pick-container d-flex flex-column">
              <h2 className="text-big blog-text-dark fw-bolder m-0">
                Relevant posts
              </h2>
              {/* tags */}
              {/* {tags && (
              <div className="d-flex align-s-center flex-wrap gap-3 px-5 space-bottom-1 mb-2">
                {tags.map(({ name }) => (
                  <BlogTag key={name}>{name}</BlogTag>
                ))}
              </div>
            )} */}
              {/* Pass relevant posts */}
              <BlogMultiplePostPreview items={relevantPosts} itemsPerRow={3} />
              <div className="text-center">
                <Link
                  to="/directory"
                  className="cta cta-button btn button-medium text-medium btn-primary bg-dark-blue"
                >
                  View all Posts
                </Link>
              </div>
            </div>
          )}
          <BlogPostMobileCTA />
        </div>

        {/* astronaut cta */}
        <BlogFooterCta />
      </Layout>
    )
  }
}

export default BlogPostTemplate

export function Head() {
  return (
    <>
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap"
        rel="preload"
        as="style"
        onload="this.onload=null;this.rel='stylesheet'"
      />
    </>
  )
}
