import React from "react"
import { Spinner } from "react-bootstrap"
class EmailCta extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      isSubmitted: false,
      isSubmitting: false,
      submissionError: false,
    }
  }

  isValidEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/ // Simple email regex
    return emailRegex.test(email)
  }
  handleInputChange = event => {
    this.setState({ email: event.target.value })
  }

  handleSubmit = async event => {
    event.preventDefault()
    const { email } = this.state
    this.setState({
      isSubmitting: true,
      isSubmitted: false,
      submissionError: false,
    })

    const formData = new FormData()
    formData.append("form-name", "wealthybites")
    formData.append("email", email)

    try {
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })

      this.setState({ isSubmitted: true, isSubmitting: false })
      this.setState({ email: "" })
    } catch (error) {
      this.setState({ submissionError: true, isSubmitting: false })
    }
  }

  render() {
    const { className } = this.props
    const { isSubmitted, submissionError, isSubmitting, email } = this.state
    const isSuccessfullySubmitted = isSubmitted && !submissionError
    return (
      <div
        className={`galaxy-background blog-border-radious ${className ?? ""}`}
      >
        <div className="d-flex email-cta-container blog-font-family p-4">
          <div className="pr-9 d-none d-lg-block">
            <img
              className="cta-email"
              src={"/img/astronaut.png"}
              loading="lazy"
              alt={"Astronaut"}
            />
          </div>
          <div
            id="content"
            className="d-flex flex-column justify-content-center"
          >
            <h4 className="text-white m-0 mb-3">
              Sign up to receive every day's top stories and insights about the
              markets!
            </h4>
            <form
              className="d-flex align-items-center email-input-container text-medium mb-3 pl-3"
              name="wealthybites"
              method="POST"
              onSubmit={this.handleSubmit}
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value="wealthybites" />
              <input
                name="email"
                disabled={isSubmitting || isSuccessfullySubmitted}
                className="fw-bold border-radius-32 email-input border-0"
                type="text"
                placeholder="Your email"
                value={this.state.email}
                onChange={this.handleInputChange}
              />
              <button
                type="submit"
                disabled={
                  !this.isValidEmail(email) ||
                  isSubmitting ||
                  isSuccessfullySubmitted
                }
                className="d-none d-lg-block cta cta-input-button btn button-medium text-medium btn-primary bg-light-blue"
              >
                {isSubmitting ? (
                  <span className="p-2">
                    <Spinner
                      animation="border"
                      variant="light"
                      className="text-white"
                    />
                  </span>
                ) : (
                  <span>
                    <img
                      className="text-white"
                      src="/svg/right-arrow.svg"
                      alt={"right arrow icon"}
                      style={{ width: "22px", height: "20px" }}
                    />
                  </span>
                )}
              </button>
              <button
                type="submit"
                disabled={
                  !this.isValidEmail(email) ||
                  isSubmitting ||
                  isSuccessfullySubmitted
                }
                className="d-block d-lg-none cta cta-input-button btn email-cta-button text-regular btn-primary bg-light-blue"
              >
                <span>
                  {isSubmitting ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <img
                      className="text-white"
                      src="/svg/right-arrow.svg"
                      alt={"right arrow icon"}
                      style={{ width: "16px", height: "14px" }}
                    />
                  )}
                </span>
              </button>
            </form>
            {isSuccessfullySubmitted && (
              <div className="text-center text-white text-big">
                Thank you for subscribing!
              </div>
            )}
            {submissionError && (
              <div className="text-center text-white text-medium">
                There was an error submitting the form. Please try again.
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default EmailCta
